<template>
  <v-container fluid class="pa-0 sync-report">
    <v-toolbar height="56">
      <day-chooser v-model="selectedDay"/>
    </v-toolbar>
    <v-card class="elevation-0">
      <template v-if="currentReportPeriod.fromTime && currentReportPeriod.toTime">
        <v-card-title class="py-1">
          Audit Report generated on {{ formatDateTitle(currentReportDate.toDate()) }}
          <v-spacer/>
          <v-text-field
              v-model="search"
              class="sync-report__search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details/>
        </v-card-title>
        <v-card-subtitle class="text-subtitle-1 pb-0">
          For the period:
          {{ formatDateSubtitle(currentReportPeriod.fromTime.toDate()) }}
          to {{ formatDateSubtitle(currentReportPeriod.toTime.toDate()) }}
        </v-card-subtitle>
      </template>
      <v-data-table
          :headers="tableHeaders"
          :items="currentReportData"
          :search="search"
          :loading="!loaded"
          item-key="resource"
          class="sync-report__audit-table"
          :items-per-page="-1"
          hide-default-footer
          height="calc(100vh - 390px)"
          fixed-header
          must-sort
          show-expand
          :expanded.sync="expanded"
          sort-by="missing"
          sort-desc
          show-group-by
          @click:row="clickRow">
        <template #expanded-item="{ headers, item }">
          <td v-if="isRoomHasBookings(item)" :colspan="headers.length" class="pa-0">
            <v-simple-table class="sync-report__expanded-row px-8 py-4">
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Title
                    </th>
                    <th class="text-left">
                      Organizer
                    </th>
                    <th class="text-left">
                      Booked period
                    </th>
                    <th class="text-left">
                      Created date
                    </th>
                    <th class="text-left">
                      Missing from calendar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                      v-for="(booking, i) in item.bookings"
                      :key="i"
                      class="sync-report__nested-row">
                    <td>{{ booking.meetingTitle || '-' }}</td>
                    <td>{{ booking.organizer.name }} ({{ booking.organizer.email }})</td>
                    <td>
                      <template v-if="booking.bookedPeriod.fromTime && booking.bookedPeriod.toTime">
                        {{ formatDate(booking.bookedPeriod.fromTime.toDate()) }} -
                        {{ formatDate(booking.bookedPeriod.toTime.toDate()) }}
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td>
                      {{ booking.bookedPeriod.createdDateTime ?
                        formatDate(booking.bookedPeriod.createdDateTime.toDate()) : '-' }}
                    </td>
                    <td>
                      <v-chip
                          v-if="booking.missingFrom"
                          class="sync-report__tag"
                          :color="getColor(booking.missingFrom)"
                          dark>
                        {{ booking.missingFrom }}
                      </v-chip>
                      <div v-else> - </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template #item.data-table-expand="{ item, isExpanded }">
          <td v-if="isRoomHasBookings(item)" class="text-start">
            <v-btn
                icon
                class="v-data-table__expand-icon"
                :class="{'v-data-table__expand-icon--active' : isExpanded}">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-if="Object.keys(currentReportSummary).length" #footer>
          <br>
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <td/>
                  <td>ERC data in Kahu accuracy</td>
                  <td>ERC data in Kahu accuracy (%)</td>
                  <td>Kahu data in ERC accuracy</td>
                  <td>Kahu data in ERC accuracy (%)</td>
                </tr>
              </thead>
              <tbody>
                <tr class="sync-report__footer">
                  <td style="width: 25%;">Total resources</td>
                  <td>{{ currentReportSummary.totalResources.accuracy }}</td>
                  <td/>
                  <td/>
                  <td/>
                </tr>
                <tr class="sync-report__footer">
                  <td>Resources @ 100%</td>
                  <td>{{ currentReportSummary.hundredPerc.accuracy }}</td>
                  <td>{{ currentReportSummary.hundredPerc.missing }}</td>
                  <td>{{ currentReportSummary.hundredPerc.accuracyOutlook }}</td>
                  <td>{{ currentReportSummary.hundredPerc.missingFromOutlook }}</td>
                </tr>
                <tr class="sync-report__footer">
                  <td>Resources &gt; 95%</td>
                  <td>{{ currentReportSummary.greater95.accuracy }}</td>
                  <td>{{ currentReportSummary.greater95.missing }}</td>
                  <td>{{ currentReportSummary.greater95.accuracyOutlook }}</td>
                  <td>{{ currentReportSummary.greater95.missingFromOutlook }}</td>
                </tr>
                <tr class="sync-report__footer">
                  <td>Resources &lt; 95%</td>
                  <td>{{ currentReportSummary.lessThan95.accuracy }}</td>
                  <td>{{ currentReportSummary.lessThan95.missing }}</td>
                  <td>{{ currentReportSummary.lessThan95.accuracyOutlook }}</td>
                  <td>{{ currentReportSummary.lessThan95.missingFromOutlook }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import DayChooser from '@/views/room-booking/calendar/DayChooser';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import {format} from 'date-fns';

export default {
  name: 'AuditSyncReport',
  components: {DayChooser},
  data() {
    return {
      tableHeaders: [
        {
          text: 'Country',
          value: 'country',
          align: 'left',
          groupable: true,
          filterable: true
        },
        {
          text: 'Site',
          value: 'site',
          align: 'left',
          groupable: true,
          filterable: true
        },
        {
          text: 'Resources',
          align: 'start',
          value: 'title',
          groupable: false,
          filterable: true
        },
        {
          text: 'Exchange Resource Calendar (ERC) events in Kahu (%)',
          value: 'accuracy',
          align: 'right',
          groupable: false
        },
        {
          text: 'Exchange Resource Calendar (ERC) events missing from Kahu',
          value: 'missing',
          align: 'right',
          groupable: false
        },
        {
          text: 'Kahu events in Exchange Resource Calendar (ERC) (%)',
          value: 'accuracyKahu',
          align: 'right',
          groupable: false
        },
        {
          text: 'Kahu events missing from Exchange Resource Calendar (ERC)',
          value: 'missingFromM365',
          align: 'right',
          groupable: false
        },
        {
          value: 'data-table-expand',
          groupable: false
        }
      ],
      expanded: [],
      search: ''
    };
  },
  computed: {
    ...mapState('views/admin/auditSyncReport', ['day']),
    ...mapGetters('views/admin/auditSyncReport', [
      'currentReportData',
      'currentReportPeriod',
      'currentReportSummary',
      'loaded',
      'currentReportDate'
    ]),

    selectedDay: {
      get() {
        return this.day;
      },
      set(v) {
        this.setDay(v);
      }
    }
  },
  mounted() {
    this.bind();
  },
  beforeDestroy() {
    this.unbind();
  },
  methods: {
    ...mapActions('views/admin/auditSyncReport', ['bind', 'unbind']),
    ...mapMutations('views/admin/auditSyncReport', ['setDay']),

    clickRow(item, e) {
      if (e.isExpanded) {
        const indexExpanded = this.expanded.findIndex(i => i === item);
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(item);
      }
    },
    formatDate(date) {
      return format(date, 'PP h:mm a');
    },
    formatDateTitle(date) {
      return format(date, 'PPPP h:mm a');
    },
    formatDateSubtitle(date) {
      return format(date, 'PPPP');
    },
    isRoomHasBookings(room) {
      return room.hasOwnProperty('bookings') && Object.keys(room.bookings).length;
    },
    getColor(tag) {
      if (tag === 'Kahu') return 'cyan';
      else if (tag === 'Outlook') return 'orange';
    }
  }
};
</script>

<style lang="scss" scoped>
.sync-report {
  &__audit-table {
    ::v-deep tbody > tr {
      cursor: pointer;
    }
  }
  &__search {
    max-width: 400px;
  }
  &__expanded-row {
    ::v-deep thead {
      cursor: default !important;
    }
  }
  &__nested-row,
  &__footer {
    cursor: default !important;
  }
  &__tag {
    min-width: 75px;
    ::v-deep .v-chip__content {
      margin: 0 auto;
    }
  }
}
</style>
