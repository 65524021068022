<template>
  <div class="day-chooser">
    <v-btn outlined @click="setToday" :color="isToday ? 'primary' : undefined">Today</v-btn>
    <v-tooltip bottom>
      <template #activator="{on}">
        <v-btn icon small class="ml-4" v-on="on" @click="addDays(-1)" :disabled="!allowPrev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </template>
      Previous Day
    </v-tooltip>
    <v-tooltip bottom>
      <template #activator="{on}">
        <v-btn icon small class="mr-4" v-on="on" @click="addDays(1)" :disabled="!allowNext">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      Next Day
    </v-tooltip>
    <v-menu offset-y v-model="dateChooserVisible" :close-on-content-click="false">
      <template #activator="{on, attrs}">
        <v-tooltip bottom>
          <template #activator="{on: tt}">
            <span v-on="{...on, ...tt}" v-bind="attrs">{{ dateString }}</span>
          </template>
          Choose Day
        </v-tooltip>
      </template>
      <v-date-picker
          v-model="date"
          no-title
          @input="dateChooserVisible = false"
          :max="max"
          :min="min"
          :first-day-of-week="localeFirstDayOfWeek"/>
    </v-menu>
  </div>
</template>

<script>
import {currentDate, startOfDay, isSameDay, fromYearMonthDay, toYearMonthDay} from '@/util/dates';
import {mapGetters} from 'vuex';

export default {
  name: 'DayChooser',
  props: {
    value: {
      type: Date,
      default() {
        return currentDate();
      }
    },
    max: {
      type: String,
      default: undefined
    },
    min: {
      type: String,
      default: undefined
    },
    today: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      dateChooserVisible: false,
      /** @type {string} */
      date: toYearMonthDay(currentDate()),
      currentDate: toYearMonthDay(currentDate()),
      currentDateTicker: 0
    };
  },
  computed: {
    ...mapGetters('sites/active', ['localeFirstDayOfWeek']),
    allowPrev() {
      if (!this.min) return true;
      return !isSameDay(fromYearMonthDay(this.date), fromYearMonthDay(this.min));
    },
    allowNext() {
      if (!this.max) return true;
      return !isSameDay(fromYearMonthDay(this.date), fromYearMonthDay(this.max));
    },
    dateString() {
      // use undefined locale to use the browser's locale
      return this.date && fromYearMonthDay(this.date).toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }) || '';
    },
    isToday() {
      if (this.today) return toYearMonthDay(this.today) === this.date;
      return this.currentDate === this.date;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(n, o) {
        const newDate = toYearMonthDay(n);
        const oldDate = toYearMonthDay(o);
        if (newDate !== oldDate) {
          this.date = newDate;
        }
      }
    },
    date(n, o) {
      if (n !== o) {
        this.$emit('input', fromYearMonthDay(n));
      }
    },
    today: {
      immediate: true,
      handler(n) {
        clearInterval(this.currentDateTicker);
        if (!n) {
          this.updateCurrentDay();
        }
      }
    }
  },
  destroyed() {
    clearInterval(this.currentDateTicker);
  },
  methods: {
    addDays(num) {
      const day = fromYearMonthDay(this.date);
      day.setDate(day.getDate() + num);
      this.date = toYearMonthDay(day);
    },
    setToday() {
      this.date = toYearMonthDay(currentDate());
    },
    updateCurrentDay() {
      clearTimeout(this.currentDateTicker);
      const date = currentDate();
      this.currentDate = toYearMonthDay(date);
      const startOfNextDay = startOfDay(date);
      startOfNextDay.setDate(startOfNextDay.getDate() + 1);
      const timeLeftInDay = startOfNextDay.getTime() - date.getTime();
      this.currentDateTicker = setTimeout(() => {
        this.updateCurrentDay();
      }, timeLeftInDay);
    }
  }
};
</script>

<style scoped>
.day-chooser {
  display: flex;
  align-items: center;
}
</style>
